<template>
  <section
    v-if="isMobile"
    :data-slice-type="slice.slice_type"
    :data-slice-variation="slice.variation"
    class="category-list"
  >
    <div class="links-wrapper">
      <div class="links-container">
        <div
          v-for="(category, index) in slice.primary.categories"
          :key="`link-${category.category_title}`"
          class="link"
          @click="jumpTo(index)"
        >
          <span :class="{ active: index == currentIndex }" class="link-text">{{
            category.category_title
          }}</span>
        </div>

        <div class="all-products-container">
          <Link
            ref="seeAllLink"
            class="see-all"
            label="See All Products"
            :light="true"
            @click="onAllProductsClick"
          />

          <div ref="otherLinks" class="others-container">
            <NuxtLink to="/baths">Baths</NuxtLink>,
            <NuxtLink to="/wardrobe">Wardrobe</NuxtLink>,
            <NuxtLink to="/doors">Doors</NuxtLink>,
            <NuxtLink to="/appliances">Appliances</NuxtLink>
          </div>
        </div>
      </div>
    </div>

    <div class="categories-container">
      <div
        v-for="(category, index) in slice.primary.categories"
        :key="category.category_title"
        :ref="
          (el) => {
            if (el) categoryRefs[index] = el;
          }
        "
        class="category"
        :data-index="index"
      >
        <div
          :ref="
            (el) => {
              if (el) imageRefs[index] = el;
            }
          "
          class="image"
        >
          <CustomImage :image="category.image" :sizes="[393, 393]" />
        </div>

        <div class="content-wrapper">
          <div
            :ref="
              (el) => {
                if (el) contentRefs[index] = el;
              }
            "
            class="content"
          >
            <h2 class="title">{{ category.category_title }}</h2>
            <p class="infos">{{ category.extra_infos }}</p>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script setup lang="ts">
import Link from "@/components/Link.vue";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { type Content } from "@prismicio/client";

const { $lenis } = useNuxtApp();

const { isMobile } = useMobile();

const seeAllLink = shallowRef(null);
const otherLinks = shallowRef(null);

// Props definition
const props = defineProps(
  getSliceComponentProps<Content.CategoryListHomepageMobileSlice>([
    "slice",
    "index",
    "slices",
    "context",
  ])
);

const router = useRouter();

// Refs for multiple elements using arrays
const categoryRefs = ref([]);
const imageRefs = ref([]);
const contentRefs = ref([]);

const currentIndex = ref(0);

const isJumping = ref(null);

let ctx;

const scope = effectScope();

onMounted(async () => {
  await nextTick();

  if (otherLinks.value) {
    gsap.set(otherLinks.value, {
      autoAlpha: 1,
      yPercent: 100,
    });
  }

  scope.run(() => {
    watch(
      isMobile,
      (newVal) => {
        if (newVal === true) {
          nextTick(() => {
            initAnimations();
            ScrollTrigger.refresh(true);
          });
        } else {
          shutMobile();
        }
      },
      {
        immediate: true,
      }
    );

    watch(
      isMobile,
      (newVal) => {
        if (newVal === true) {
          nextTick(() => {
            initAnimations();
            ScrollTrigger.refresh(true);
          });
        } else {
          shutMobile();
        }
      },
      {
        immediate: true,
      }
    );
  });
});

watch(currentIndex, (newVal) => {
  if (newVal == props.slice.primary.categories.length - 1) {
    gsap.to(otherLinks.value, {
      yPercent: 0,
      opacity: 1,
      duration: 0.8,
      ease: "expo.out",
    });
    gsap.set(seeAllLink.value.linkElement.$el, {
      pointerEvents: "none",
    });
    gsap.set(otherLinks.value, {
      pointerEvents: "all",
    });
    if (seeAllLink.value) {
      gsap.to(seeAllLink.value.linkElement.$el, {
        yPercent: -100,
        opacity: 0,
        duration: 0.8,
        ease: "expo.out",
      });
    }
  } else {
    if (seeAllLink.value) {
      gsap.to(seeAllLink.value.linkElement.$el, {
        yPercent: 0,
        opacity: 1,
        duration: 0.8,
        ease: "expo.out",
      });
    }

    gsap.set(seeAllLink.value.linkElement.$el, {
      pointerEvents: "all",
    });
    gsap.set(otherLinks.value, {
      pointerEvents: "none",
    });
    gsap.to(otherLinks.value, {
      yPercent: 100,
      opacity: 0,
      duration: 0.8,
      ease: "expo.out",
    });
  }
});

const onAllProductsClick = (e) => {
  e.preventDefault();

  let redirect;

  switch (currentIndex.value) {
    case 0:
      redirect = "/floors";
      break;
    case 1:
      redirect = "/walls-ceilings";
      break;
    case 2:
      redirect = "/kitchens";
      break;
    case 3:
      redirect = "/baths";
      break;
    default:
      redirect = "/floors";
      break;
  }

  router.push(redirect);
};

const initAnimations = () => {
  ctx = gsap.context(() => {
    imageRefs.value.forEach((img) => {
      if (img) {
        gsap.to(img, {
          yPercent: 30,
          ease: "none",
          scrollTrigger: {
            trigger: img.parentNode,
            scrub: 0,
          },
        });
      }
    });

    // Category scroll tracking
    categoryRefs.value.forEach((el) => {
      if (el) {
        ScrollTrigger.create({
          trigger: el,
          start: "30% bottom",
          end: "bottom center",
          onEnter: () => {
            if (isJumping.value) return;
            currentIndex.value = el.dataset.index;
          },
          onEnterBack: () => {
            if (isJumping.value) return;
            currentIndex.value = el.dataset.index;
          },
        });
      }
    });
  });
};

const jumpTo = (index) => {
  isJumping.value = true;
  currentIndex.value = index;

  if (categoryRefs.value[index]) {
    const target = categoryRefs.value[index].offsetTop + window.innerHeight;
    $lenis.scrollTo(target, {
      onComplete: () => {
        currentIndex.value = index;
        isJumping.value = false;
      },
    });
  }
};

const shutMobile = () => {
  ctx?.kill(true);
};

onBeforeUnmount(() => {
  scope.stop();
});

onUnmounted(() => {
  shutMobile();
});
</script>

<style lang="scss" scoped>
.category-list {
  display: flex;
  flex-direction: column;
  position: relative;
  @include desktop {
    display: none;
  }
}

.category {
  height: 100lvh;
  width: 100%;
  position: relative;
  overflow: hidden;
}

.image {
  width: 100%;
  height: 100%;
  transform: translateY(-15%);
}

.content-wrapper {
  position: absolute;
  inset: 0;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  padding: var(--padding-mobile);
  z-index: 1;
  pointer-events: none;
}

.title {
  @include headline-big-a;
  color: $white;
  text-transform: uppercase;
  margin-bottom: 1.5rem;
}

.infos {
  color: $white;
  font-family: $beaufort-regular;
  font-size: 2.3rem;
  font-style: normal;
  font-weight: 400;
  line-height: 2.6rem;
  letter-spacing: -0.046rem;
  text-decoration-line: underline;
  text-underline-offset: 3px;
  text-decoration-thickness: 0.7px;
}

.see-all {
  margin-top: 2rem;
}

.links-wrapper {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  padding: var(--padding-mobile);
}

.links-container {
  position: sticky;
  height: 50svh;
  top: 6rem;
}

.link-text {
  color: $white;
  opacity: 0.5;
  @include link;
  text-transform: uppercase;
  transition: opacity 0.3s ease;
  &.active {
    opacity: 1;
  }
}

.all-products-container {
  overflow: hidden;
  position: relative;
  height: 8rem;
}

.others-container {
  position: absolute;
  left: 0;
  top: 0;
  display: flex;
  flex-wrap: wrap;
  width: 12rem;
  display: inline-block;
  @include link;
  color: white;
  text-transform: uppercase;
  visibility: hidden;
  margin-top: 2rem;
  a {
    @include link;
    @include link-active-full-hover;
    &::before,
    &::after {
      background-color: $white;
    }
  }
}
</style>
